import { useSelector } from 'react-redux';
import { adminRolePermissions } from './roles';

export const checkPermission = ({ role, menu, permission }) => {
  const { authUser } = useSelector((state) => ({
    authUser: state.auth.user,
  }));

  if (authUser?.role === 'super-admin' || authUser?.permissions?.[menu]?.includes(permission)) {
    return true;
  } else {
    return false;
  }
};

export const usePermissions = ({ menu }) => {
  const { authUser } = useSelector((state) => ({
    authUser: state.auth.user,
  }));
  console.log(authUser.permissions, 'Authh');
  return authUser?.permissions;
};

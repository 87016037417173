import React, { lazy, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { usePermissions } from '../../utility/checkPermissions';

const ViewUser = lazy(() => import('../../container/pages/overview/ViewUser'));
const UpdateUser = lazy(() => import('../../container/pages/overview/UpdateUser'));
const AddUser = lazy(() => import('../../container/users/AddUsers'));
const DataTable = lazy(() => import('../../container/users/UserListDataTable'));
const NotFound = lazy(() => import('../../container/pages/404'));

function PagesRoute() {
  const [permissions, setPermissions] = useState([]);
  const { authUser } = useSelector((state) => {
    return {
      authUser: state.auth.user,
    };
  });

  const permissionsFromHook = usePermissions('users');

  useEffect(() => {
    setPermissions(permissionsFromHook);
  }, [permissionsFromHook]);
  console.log(permissions);
  return (
    <Routes>
      {(authUser?.isSuperAdmin || permissions?.users?.includes('read')) && (
        <>
          <Route path="/" element={<DataTable />} />
          {(authUser?.isSuperAdmin || permissions?.users?.includes('create')) && (
            <Route path="/add-user" element={<AddUser />} />
          )}

          <Route path="get-user/:id" element={<ViewUser />} />
          {(authUser?.isSuperAdmin || permissions?.users?.includes('update')) && (
            <Route path="/update-user/:id" element={<UpdateUser />} />
          )}
        </>
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PagesRoute;

import React, { lazy, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { usePermissions } from '../../utility/checkPermissions';
import { useSelector } from 'react-redux';

const ViewCareGiver = lazy(() => import('../../container/pages/overview/ViewCareGiver'));
const ApproveUpdateCareGiver = lazy(() => import('../../container/pages/overview/ApprovedUpdateCaregiver'));
const AddCaregiver = lazy(() => import('../../container/caregivers/AddCaregiver'));
const CaregiversList = lazy(() => import('../../container/pages/overview/ApproveCaregiver'));
const DataTable = lazy(() => import('../../container/caregivers/CaregiversList'));
const NotFound = lazy(() => import('../../container/pages/404'));

function PagesRoute() {
  const [permissions, setPermissions] = useState([]);
  const { authUser } = useSelector((state) => {
    return {
      authUser: state.auth.user,
    };
  });
  const permissionsFromHook = usePermissions('caregivers');

  useEffect(() => {
    setPermissions(permissionsFromHook);
  }, [permissionsFromHook]);

  return (
    <Routes>
      {(authUser?.isSuperAdmin || permissions?.caregivers?.includes('read')) && (
        <>
          <Route path="/" element={<DataTable />} />
          {(authUser?.isSuperAdmin || permissions?.caregivers?.includes('create')) && (
            <Route path="/add/*" element={<AddCaregiver />} />
          )}
          <Route path="/update-approvecaretaker/:id" element={<ApproveUpdateCareGiver />} />
          <Route path="/" element={<CaregiversList />} />
          <Route path="/get-caretaker/:id" element={<ViewCareGiver />} />
        </>
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PagesRoute;

import { query, where, addDoc, doc, collection, getDocs, getDoc, deleteDoc, updateDoc } from '@firebase/firestore';
import { auth, firestore } from '../config/api/firebase';
import { message } from 'antd';

const ref = collection(firestore, 'dashUsers'); // Firebase creates this automatically

export const loginDashboardAdmin = async (email, password) => {
  try {
    const q = query(collection(firestore, 'dashUsers'), where('email', '==', email));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userData = querySnapshot.docs[0].data();
      userData.id = querySnapshot.docs[0].id;
      if (userData.password == password) {
        console.log(userData);
        return { success: true, user: userData };
      } else {
        return { success: false, error: 'Username and Password do not match!' };
      }
    } else {
      console.log('User not found');
      return { success: false, error: 'No user found!' };
    }
  } catch (error) {
    console.error('Error fetching user:', error);
    return { success: false, error: 'Something went wrong!' };
  }
};

export const getAllManagementUsers = async () => {
  const dashboardAdminList = [];
  const data = await getDocs(ref);
  data?.forEach((doc) => {
    dashboardAdminList.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return dashboardAdminList;
};

export const createManagementUser = async (userData) => {
  const { email } = userData;

  // Check if a user with the same email already exists
  const usersRef = collection(firestore, 'dashUsers');
  const emailQuery = query(usersRef, where('email', '==', email));
  const existingUsers = await getDocs(emailQuery);

  if (!existingUsers.empty) {
    // A user with the same email already exists
    throw new Error('User with this email already exists.');
  }

  // Create the user if the email is unique
  try {
    const usersCollectionRef = collection(firestore, 'dashUsers');
    const docRef = await addDoc(usersCollectionRef, userData);

    console.log('User created with ID: ', docRef.id);
    // await sendEmailConfirmation(email);
    return docRef.id; // Return the ID of the created user
  } catch (error) {
    console.error('Error creating user: ', error.message);
    throw error; // Re-throw the error to handle it at the caller level if needed
  }
};
// export const getDashboardAdmin = async (id) => {
//   try {
//     const response = dashboardUsersCollection.doc(id).update(data);
//     return response;
//   } catch (error) {}
// };
// export const updateDashboardAdmin = async (id, data) => {
//   try {
//     const response = dashboardUsersCollection.doc(id).update(data);
//     return response;
//   } catch (error) {}
// };

export const updateManagementById = async (userId, updatedUserData) => {
  const userRef = doc(firestore, 'dashUsers', userId);

  try {
    await updateDoc(userRef, updatedUserData);
    console.log(`Management with ID ${userId} successfully updated.`);
    return true; // Return true to indicate successful update
  } catch (error) {
    console.error('Error updating Management:', error.message);
    return false; // Return false to indicate failure
  }
};

export const getManagementById = async (userId) => {
  const userRef = doc(firestore, 'dashUsers', userId);

  const userDoc = await getDoc(userRef);

  if (userDoc.exists()) {
    return {
      id: userDoc.id,
      ...userDoc.data(),
    };
  } else {
    console.error('Error getting  management by id:', error.message);
    return null;
  }
};

export const deleteManagementUser = async (id) => {
  const userRef = doc(firestore, 'dashUsers', id);

  try {
    await deleteDoc(userRef);
    console.log(`User with ID ${id} successfully deleted.`);
    return true; // Return true to indicate successful deletion
  } catch (error) {
    console.error('Error deleting user:', error.message);
    return false; // Return false to indicate failure
  }
};

export const countTotalManagement = async () => {
  const collectionRef = collection(firestore, 'dashUsers');
  const querySnapshot = await getDocs(collectionRef);

  return querySnapshot.size;
};

export const updatePassword = async (userId, data) => {
  console.log(userId);
  console.log(data);
  try {
    const userDocRef = doc(firestore, 'dashUsers', userId);

    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      console.log();
      if (userData.password == data.oldPass) {
        const res = await updateDoc(userDocRef, { password: data.password });
        message.success('Password updated successfully');
        return res; // Indicate success
      } else {
        message.error('Old Password Mismatch!');
        return false;
      }
    } else {
      console.error('User not found.');
      return false; // Indicate failure
    }
  } catch (error) {
    console.error('Error updating password: ', error.message);
    throw error; // Re-throw the error to handle it at the caller level if needed
  }
};

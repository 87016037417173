import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const ViewRole = lazy(() => import('../../container/roles/ViewRole'));
const AddRole = lazy(() => import('../../container/roles/AddRole'));
const UpdateRole = lazy(() => import('../../container/roles/UpdateRole'));
const RolesDataTable = lazy(() => import('../../container/roles/Roles'));
const NotFound = lazy(() => import('../../container/pages/404'));

function RolesRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RolesDataTable />} />
      <Route path="add" element={<AddRole />} />
      <Route path=":id" element={<ViewRole />} />
      <Route path="update/:id" element={<UpdateRole />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default RolesRoutes;

import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { updateCaretaker } from '../../api/caregivers';
import { message } from 'antd';

const UpdateCard = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const id = params.get('id');
  const connectAccountId = params.get('connectAccountId');
  const connectAccountCard = params.get('connectAccountCard');
  console.log(id);
  const callApi = async (id, connectAccountId = null, connectAccountCard = null) => {
    try {
      const res = await updateCaretaker(id, {
        connectAccountId: connectAccountId,
        connectAccountCard: connectAccountCard,
      });
      message.success('Payment Added');
    } catch (error) {
      message.error('Something went wrong!');
    }
  };
  useEffect(() => {
    if (id && (connectAccountId || connectAccountCard)) {
      callApi(id, connectAccountId, connectAccountCard);
    }
    // navigate('/');
  }, []);
  return <></>;
};

export default UpdateCard;

import React, { lazy, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { adminRolePermissions } from '../../utility/roles';
import { usePermissions } from '../../utility/checkPermissions';

const UpdateManagementUser = lazy(() => import('../../container/pages/overview/UpdateManagementUser'));
const GetManagementUser = lazy(() => import('../../container/pages/overview/GetManagementUser'));
const ManagementUsers = lazy(() => import('../../container/management-users/ManagementUsers'));
const AddManagementUsers = lazy(() => import('../../container/management-users/AddManagementUsers'));
const DataTable = lazy(() => import('../../container/management-users/ManagementUserListDataTable'));
const Team = lazy(() => import('../../container/management-users/Team'));
const NotFound = lazy(() => import('../../container/pages/404'));

function PagesRoute() {
  const [permissions, setPermissions] = useState([]);
  const { authUser } = useSelector((state) => {
    return {
      authUser: state.auth.user,
    };
  });
  const permissionsFromHook = usePermissions('users');

  useEffect(() => {
    setPermissions(permissionsFromHook);
  }, [permissionsFromHook]);

  return (
    <Routes>
      {(authUser?.isSuperAdmin || permissions?.management?.includes('read')) && (
        <>
          <Route path="/" element={<DataTable />} />
          {(authUser?.isSuperAdmin || permissions?.management?.includes('create')) && (
            <Route path="add" element={<AddManagementUsers />} />
          )}
          {(authUser?.isSuperAdmin || permissions?.management?.includes('update')) && (
            <Route path="update/:id" element={<UpdateManagementUser />} />
          )}
          <Route path=":id" element={<GetManagementUser />} />
        </>
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PagesRoute;

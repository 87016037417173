import { combineReducers } from 'redux';
import themeUsersReducer from './themeUsers/reducers';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { userReducer, userGroupReducer } from './users/reducers';
import { headerSearchReducer } from './headerSearch/reducers';
import orderReducer from './orders/reducers';
import { emailReducer, SingleEmailReducer } from './email/reducers';
import tickets from './supportTickets/reducers';
import dataTable from './data-filter/reducers';

const rootReducers = combineReducers({
  themeUsers: themeUsersReducer,
  headerSearchData: headerSearchReducer,
  orders: orderReducer,
  users: userReducer,
  userGroup: userGroupReducer,
  auth: authReducer,
  email: emailReducer,
  emailSingle: SingleEmailReducer,
  ChangeLayoutMode,
  tickets,
  dataTable,
});

export default rootReducers;

import { addDoc, doc, collection, getDocs, getDoc, deleteDoc, updateDoc, query, where } from '@firebase/firestore';
import { firestore } from '../config/api/firebase';

export const getAllCaretakers = async () => {
  const careTakerList = [];
  const ref = collection(firestore, 'careTakers'); // Firebase creates this automatically
  const data = await getDocs(ref);
  data?.forEach((doc) => {
    careTakerList.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return careTakerList;
};

export const getApprovedCaretakers = async () => {
  const careTakerList = [];
  const ref = collection(firestore, 'careTakers');
  const query = query(ref, where('approved', '==', true)); // Query for approved caretakers
  const data = await getDocs(query);
  console.log(query, 'approved data');

  data?.forEach((doc) => {
    careTakerList.push({
      id: doc.id,
      ...doc.data(),
    });
  });

  return careTakerList;
};

export const getPendingCaretakers = async () => {
  // const careTakerList = [];
  // const ref = collection(firestore, 'careTakers');
  // const query = query(ref, where('approved', '==', false)); // Query for approved caretakers
  // const data = await getDocs(query);
  const careTakerList = [];
  const ref = collection(firestore, 'careTakers'); // Firebase creates this automatically
  const data = await getDocs(ref);
  data?.forEach((doc) => {
    if (doc.approved == false)
      careTakerList.push({
        id: doc.id,
        ...doc.data(),
      });
  });

  return careTakerList;
};

export const createCaretaker = async (userData) => {
  const { email } = userData;

  // Check if a user with the same email already exists
  const usersRef = collection(firestore, 'careTakers');
  const emailQuery = query(usersRef, where('email', '==', email));
  const existingUsers = await getDocs(emailQuery);

  if (!existingUsers.empty) {
    // A user with the same email already exists
    throw new Error('careTakers with this email already exists.');
  }

  // Create the user if the email is unique
  try {
    const usersCollectionRef = collection(firestore, 'careTakers');
    const docRef = await addDoc(usersCollectionRef, userData);

    console.log('careTakers created with ID: ', docRef.id);

    return docRef.id; // Return the ID of the created user
  } catch (error) {
    console.error('Error creating user: ', error.message);
    throw error; // Re-throw the error to handle it at the caller level if needed
  }
};

export const getCareTakerById = async (userId) => {
  const userRef = doc(firestore, 'careTakers', userId);

  const userDoc = await getDoc(userRef);

  if (userDoc.exists()) {
    return {
      id: userDoc.id,
      ...userDoc.data(),
    };
  } else {
    console.error('Error getting  caretaker by id:', userId);
    return null;
  }
};

export const updateCaretaker = async (userId, updatedUserData) => {
  const userRef = doc(firestore, 'careTakers', userId);

  try {
    // Check if the user exists by fetching the document
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      // User exists, proceed with the update
      const res = await updateDoc(userRef, updatedUserData);
      console.log(`careTaker with ID ${userId} successfully updated.`);
      return `careTaker with ID ${userId} successfully updated.`; // Return true to indicate successful update
    } else {
      console.error(`careTaker with ID ${userId} does not exist.`);
      return `careTaker with ID ${userId} does not exist.`; // Return false to indicate failure (user not found)
    }
  } catch (error) {
    console.error('Error updating careTaker:', error.message);
    return `CareTaker does not exists.`; // Return false to indicate failure
  }
};
export const deleteCaretaker = async (userId) => {
  const userRef = doc(firestore, 'careTakers', userId);

  try {
    await deleteDoc(userRef);
    console.log(`User with ID ${userId} successfully deleted.`);
    return true; // Return true to indicate successful deletion
  } catch (error) {
    console.error('Error deleting user:', error.message);
    return false; // Return false to indicate failure
  }
};

export const approveCareTaker = async (email) => {
  // Check if a user with the same email already exists
  const usersRef = collection(firestore, 'careTakers');

  // Ensure that the email is defined before creating the query
  if (email) {
    const emailQuery = query(usersRef, where('email', '==', email));
    const existingUsers = await getDocs(emailQuery);

    if (existingUsers.size === 0) {
      console.error(`careTaker with email ${email} not found.`);
      return false; // Return false to indicate failure
    }

    // Assuming there is only one user with the provided email
    const userDoc = existingUsers.docs[0];
    const userRef = doc(firestore, 'careTakers', userDoc.id);

    try {
      // Update the careTaker password in Firestore
      await updateDoc(userRef, { approved: approved ? 'true' : 'false' });

      console.log(`careTaker with email ${email} successfully approved.`);
      return true; // Return true to indicate successful update
    } catch (error) {
      console.error(`Error updating careTaker with email ${email}:`, error.message);
      return false; // Return false to indicate failure
    }
  } else {
    // console.error('Email is undefined.');
    return false; // Return false to indicate failure
  }
};

export const countTotalCaretakers = async () => {
  const collectionRef = collection(firestore, 'careTakers');
  const querySnapshot = await getDocs(collectionRef);

  return querySnapshot.size;
};

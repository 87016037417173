// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from '@firebase/firestore';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getStorage, ref } from 'firebase/storage';
import { getMessaging, getToken } from 'firebase/messaging';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyA69xUmL3pacenwcU9TIeJjAgfKra_Gv0M',
  authDomain: 'hailo-a246d.firebaseapp.com',
  projectId: 'hailo-a246d',
  storageBucket: 'hailo-a246d.appspot.com',
  messagingSenderId: '890429033978',
  appId: '1:890429033978:web:f0864492d9d5f8816b2843',
  measurementId: 'G-1N6F0PQRQ7',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);
const auth = getAuth(app); // Corrected line
const storage = getStorage(app);
const messaging = getMessaging(app);

const predefinedUsername = 'webapp@gmail.com';
const predefinedPassword = 'Webapp@123!';
signInWithEmailAndPassword(auth, predefinedUsername, predefinedPassword) // Corrected line
  .then((userCredential) => {
    // User successfully signed in
    const user = userCredential.user;
    console.log('Automatic sign-in successful:', user);
  })
  .catch((error) => {
    // Handle errors
    console.error('Automatic sign-in error:', error.message);
  });
const sendNotification = async (deviceToken, title, message) => {
  const apiUrl = 'https://fcm.googleapis.com/fcm/send';
  const serverKey =
    'AAAAz1G0zfo:APA91bHUVIPrMa8wqIzqG8I7a-0XAFyQCFwogU9GGTgHhnLjXYCeDrjKau8abpk7vb2noz5enXzgvi1jcNWvPOKCZTHkxvvHJo-6oTap7bUhYWBYJv4PQH6SFAlbjCRC5s1uHlIAB1S0'; // Replace with your Firebase server key

  const notification = {
    to: deviceToken,
    notification: {
      title: title,
      body: message,
    },
  };

  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `key=${serverKey}`,
    },
    body: JSON.stringify(notification),
  });

  const responseData = await response.json();
  console.log('Notification sent:', responseData);
};

export { auth, storage, messaging, sendNotification, firestore };

import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Dashboard from './dashboard';
import Users from './users';
import Caregivers from './caregiver';
import PendingCaregivers from './pendingcaregiver';
import ManagementUsers from './management';
import Roles from './roles';
import withAdminLayout from '../../layout/withAdminLayout';
import Stripe from '../../components/stripe';
import { useSelector } from 'react-redux';

const Settings = lazy(() => import('./pages'));
const Transactions = lazy(() => import('./transaction'));
const Withdrawals = lazy(() => import('./withdrawals'));
const Tickets = lazy(() => import('../../container/supportTicket/Index'));
const AddTicket = lazy(() => import('../../container/supportTicket/AddSupport'));
const TicketDetails = lazy(() => import('../../container/supportTicket/SupportTicketDetails'));
const Myprofile = lazy(() => import('../../container/profile/myProfile/Index'));
const CareGiverTickets = lazy(() => import('./caregiversupport'));
const NotFound = lazy(() => import('../../container/pages/404'));

const Admin = React.memo(() => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { authUser } = useSelector((state) => {
    return {
      authUser: state.auth.user,
    };
  });
  useEffect(() => {
    (authUser?.isSuperAdmin || authUser?.permissions?.dashboard?.includes('read')) ?? navigate('/admin/pages/settings');
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="spin flex items-center justify-center bg-white dark:bg-dark h-screen w-full fixed z-[999] ltr:left-0 rtl:right-0 top-0">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route path="pages/*" element={<Settings />} />
        <Route index path="/*" element={<Dashboard />} />
        <Route path="users/*" element={<Users />} />
        <Route path="transactions/*" element={<Transactions />} />
        <Route path="withdrawals/*" element={<Withdrawals />} />
        <Route path="approve-caregiver/*" element={<Caregivers />} />
        <Route path="pending-caregiver/*" element={<PendingCaregivers />} />
        <Route path="management-users/*" element={<ManagementUsers />} />
        <Route path="roles/*" element={<Roles />} />
        <Route path="app/caregiver-support/tickets/*" element={<CareGiverTickets />} />
        <Route path="app/support/tickets/*" element={<Tickets />} />
        <Route path="app/support/tickets/add" element={<AddTicket />} />
        <Route path="app/support/ticketDetails/:id" element={<TicketDetails />} />
        <Route path="profile/myProfile/*" element={<Myprofile />} />
        <Route path="payment/:id" element={<Stripe />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);

import { addDoc, collection, getDocs, getDoc, doc, deleteDoc, updateDoc, query, where } from '@firebase/firestore';
import { firestore } from '../config/api/firebase';

export const getAllUsers = async () => {
  const usersList = [];
  const ref = collection(firestore, 'users'); // Firebase creates this automatically
  const data = await getDocs(ref);
  data?.forEach((doc) => {
    usersList.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return usersList;
};

export const createUser = async (userData) => {
  const { email } = userData;
  // Check if a user with the same email already exists
  const usersRef = collection(firestore, 'users');
  const emailQuery = query(usersRef, where('email', '==', email));
  const existingUsers = await getDocs(emailQuery);
  if (!existingUsers.empty) {
    // A user with the same email already exists
    throw new Error('User with this email already exists.');
  }
  // Create the user if the email is unique
  try {
    const usersCollectionRef = collection(firestore, 'users');
    const docRef = await addDoc(usersCollectionRef, userData);
    return docRef.id; // Return the ID of the created user
  } catch (error) {
    console.error('Error creating user: ', error.message);
    throw error; // Re-throw the error to handle it at the caller level if needed
  }
};

export const getUserById = async (userId) => {
  const userRef = doc(firestore, 'users', userId);
  const userDoc = await getDoc(userRef);
  if (userDoc.exists()) {
    return {
      id: userDoc.id,
      ...userDoc.data(),
    };
  } else {
    console.error('Error getting  user by id:', userId);
    return null;
  }
};

export const updateUserById = async (userId, updatedUserData) => {
  const userRef = doc(firestore, 'users', userId);
  try {
    await updateDoc(userRef, updatedUserData);
    return true; // Return true to indicate successful update
  } catch (error) {
    console.error('Error updating user:', error.message);
    return false; // Return false to indicate failure
  }
};

export const updatePassword = async (email, password) => {
  // Check if a user with the same email already exists
  const usersRef = collection(firestore, 'dashUsers');
  const emailQuery = query(usersRef, where('email', '==', email));
  const existingUsers = await getDocs(emailQuery);
  if (existingUsers.size === 0) {
    console.error(`User with email ${email} not found.`);
    return false; // Return false to indicate failure
  }
  // Assuming there is only one user with the provided email
  const userDoc = existingUsers.docs[0];
  const userRef = doc(firestore, 'dashUsers', userDoc.id);
  try {
    await updateDoc(userRef, { password });
    return true; // Return true to indicate successful update
  } catch (error) {
    console.error('Error updating user:', error.message);
    return false; // Return false to indicate failure
  }
};

export const deleteUserById = async (userId) => {
  const userRef = doc(firestore, 'users', userId);
  try {
    await deleteDoc(userRef);
    return true; // Return true to indicate successful deletion
  } catch (error) {
    console.error('Error deleting user:', error.message);
    return false; // Return false to indicate failure
  }
};

export const resetPasswordLink = async (email) => {
  // Create the user if the email is unique
  try {
    const resetRef = collection(firestore, 'reset_tokens');
    const docRef = await addDoc(resetRef, { email });
    return docRef.id; // Return the ID of the created user
  } catch (error) {
    console.error('Error generating token: ', error.message);
    throw error; // Re-throw the error to handle it at the caller level if needed
  }
};

export const getResetToken = async (id) => {
  const resetTokenRef = doc(firestore, 'reset_tokens', id);
  const resetTokenDoc = await getDoc(resetTokenRef);
  if (resetTokenDoc.exists()) {
    return {
      id: resetTokenDoc.id,
      ...resetTokenDoc.data(),
    };
  } else {
    console.error('Token not found!');
    return 'Invalid Token';
  }
};

export const countTotalUsers = async () => {
  const collectionRef = collection(firestore, 'users');
  const querySnapshot = await getDocs(collectionRef);
  return querySnapshot.size;
};

import React, { useEffect, useState } from 'react';
import { updateCaretaker } from '../../api/caregivers';
import { message } from 'antd';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { DataService } from '../../config/dataService/dataService';
const ApiUrl = process.env.REACT_APP_API_ENDPOINT;

const OnboardSuccess = () => {
  const { search } = useLocation();
  const [error, setError] = useState('OnboardSuccess');
  const searchParams = new URLSearchParams(search);

  // Get the values of accID and uid parameters
  const accountID = searchParams.get('accID');
  const uid = searchParams.get('uid');

  const callApi = async () => {
    try {
      const params = { accId: accountID };
      const response = await DataService.post(`/card/connected`, params);
      console.log(response.data);
      if (uid) {
        const res = await updateCaretaker(uid, {
          connectAccountId: accountID,
          connectAccountCard: response?.data?.cardDetails?.id ?? '',
        });
      } else {
        setError('Caretaker ID not found.');
        message.error('Caretaker ID not found.');
      }
    } catch (error) {
      console.log(error);
      setError(error.message);
      message.error('Something went wrong!');
    }
  };
  useEffect(() => {
    callApi();
  }, []);
  return <div>{error}</div>;
};

export default OnboardSuccess;

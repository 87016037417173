import {
  UilUserSquare,
  UilUserMd,
  UilCreateDashboard,
  UilHeadphones,
  UilUsersAlt,
  UilTransaction,
} from '@iconscout/react-unicons';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import UilLock from '@iconscout/react-unicons/icons/uil-lock';
import UilSetting from '@iconscout/react-unicons/icons/uil-setting';
function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const { authUser } = useSelector((state) => {
    return {
      authUser: state.auth.user,
    };
  });
  console.log(authUser);
  const path = '/admin';
  const [openKeys, setOpenKeys] = React.useState([]);
  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };
  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };
  const items = [
    (authUser?.isSuperAdmin || authUser?.permissions?.dashboard?.includes('read')) &&
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}`}>
          {t('Dashboard')}
        </NavLink>,
        '/admin',
        <UilCreateDashboard />,
      ),
    (authUser?.isSuperAdmin || authUser?.permissions?.users?.includes('read')) &&
      getItem(t('Clients'), 'Clients', <UilUsersAlt />, [
        (authUser?.isSuperAdmin || authUser?.permissions?.users?.includes('create')) &&
          // getItem(
          //   <NavLink onClick={toggleCollapsed} to={`${path}/users/add-user`}>
          //     {t('add')} {t('user')}
          //   </NavLink>,
          //   'addUser',
          //   null,
          // ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/users`}>
              {t('clients')}
            </NavLink>,
            'user-list',
            null,
          ),
      ]),
    (authUser?.isSuperAdmin || authUser?.permissions?.caregivers?.includes('read')) &&
      getItem(t('Caregiver'), 'Caregiver', <UilUserSquare />, [
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/approve-caregiver`}>
            {t('approved')} {t('caregivers')}
          </NavLink>,
          'approvecaregiver-list',
          null,
        ),
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/pending-caregiver`}>
            {t('pending')} {t('caregivers')}
          </NavLink>,
          'pendingcaregiver-list',
          null,
        ),
      ]),
    (authUser?.isSuperAdmin || authUser?.permissions?.transaction?.includes('read')) &&
      getItem(t('Transaction'), 'Transaction', <UilTransaction />, [
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/transactions`}>
            {t('transaction')}
          </NavLink>,
          'transaction-list',
          null,
        ),
      ]),
    (authUser?.isSuperAdmin || authUser?.permissions?.withdrawals?.includes('read')) &&
      getItem(t('Withdrawal'), 'Withdrawal', <UilTransaction />, [
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/withdrawals/approved`}>
            {t('approved')} {t('withdrawal')}
          </NavLink>,
          'approved-withdrawal-list',
          null,
        ),
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/withdrawals/unapproved`}>
            {t('unapproved')} {t('withdrawal')}
          </NavLink>,
          'unapproved-withdrawal-list',
          null,
        ),
      ]),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/app/support/tickets`}>
    //     {t('Support Ticket')}
    //   </NavLink>,
    //   'support-ticket',
    //   <UilHeadphones />,
    // ),
    (authUser?.isSuperAdmin || authUser?.permissions?.supportchat?.includes('read')) &&
      getItem(t('Support Ticket'), 'support-ticket', <UilHeadphones />, [
        (authUser?.isSuperAdmin || authUser?.permissions?.supportchat?.includes('caregiversupport')) &&
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/app/caregiver-support/tickets`}>
              {t('Caregiver')} {t('Support')}
            </NavLink>,
            'caregiver-support',
            null,
          ),
        (authUser?.isSuperAdmin || authUser?.permissions?.supportchat?.includes('usersupport')) &&
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/app/support/tickets`}>
              {t('User')} {t('Support')}
            </NavLink>,
            'user-support',
            null,
          ),
      ]),
    (authUser?.isSuperAdmin || authUser?.permissions?.management?.includes('read')) &&
      getItem(t('Management'), 'Management', <UilUserMd />, [
        (authUser?.isSuperAdmin || authUser?.permissions?.management?.includes('create')) &&
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/management-users/add`}>
              {t('add')} {t('Management')}
            </NavLink>,
            'management-users-add',
            null,
          ),
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/management-users`}>
            {t('Management')}
          </NavLink>,
          'management-users-list',
          null,
        ),
      ]),
    (authUser?.isSuperAdmin || authUser?.permissions?.roles?.includes('read')) &&
      getItem(t('Roles'), 'Roles', <UilLock />, [
        (authUser?.isSuperAdmin || authUser?.permissions?.roles?.includes('create')) &&
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/roles/add`}>
              {t('add')} {t('role')}
            </NavLink>,
            'role-add',
            null,
          ),
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/roles`}>
            {t('roles')}
          </NavLink>,
          'roles-list',
          null,
        ),
      ]),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/settings`}>
        {t('settings')}
      </NavLink>,
      'settings',
      <NavLink className="menuItem-iocn" to={`${path}/pages/settings`}>
        <UilSetting />
      </NavLink>,
    ),
  ];
  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode="inline"
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}
MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};
export default MenuItems;

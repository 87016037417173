import { Button, Col, Form, Input, Row, message, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import logo from '../../images/HailoIcon.jpg';
import { getResetToken, updatePassword } from '../../api/users';

const Reset = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState();

  const navigate = useNavigate();
  const params = useParams();
  const callApi = async () => {
    const res = await getResetToken(params.token);
    if (res?.email) {
      setEmail(res?.email);
    } else {
      message.error(error);
      navigate('/reset-password');
    }
  };

  useEffect(() => {
    callApi();
  }, []);
  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      console.log(values);
      const changePass = await updatePassword(email, values.password);
      notification.success({
        message: 'Password Changed',
        description: `Password has been changed successfully.`,
      });
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Password Changed Failed',
        description: `Error in changing passsword.`,
      });
    }

    setIsLoading(false);
  };

  const confirmPasswordValidator = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Password do not match.'));
    },
  });
  return (
    <Row justify="center">
      <Col xxl={6} xl={8} md={12} sm={18} xs={24}>
        <div className="mt-6 bg-white rounded-md dark:bg-white10 shadow-regular dark:shadow-none">
          <div className="flex justify-center px-5 py-4 gap-2 text-center border-b border-gray-200 dark:border-white10">
            <img src={logo} alt="" className="w-[30px] rounded" />
            <h2 className="mb-0 text-xl font-semibold text-dark dark:text-white87">Reset Password </h2>
          </div>
          <div className="px-10 pt-8 pb-6">
            <Form name="login" form={form} onFinish={handleSubmit} layout="vertical">
              <Form.Item
                name="password"
                label="Password"
                className="[&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                rules={[{ required: true, message: 'Please confirm your password!' }, confirmPasswordValidator]}
                className="[&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
              >
                <Input.Password placeholder="Confirm Password" />
              </Form.Item>
              <div className="flex flex-wrap items-center justify-between gap-[10px]">
                {/* <Checkbox onChange={onChange} checked={state.checked} className="text-xs text-light dark:text-white60">
              Keep me logged in
            </Checkbox> */}
                <NavLink className=" text-primary text-13" to="/">
                  Back to login.
                </NavLink>
              </div>
              <Form.Item>
                <Button
                  className="w-full h-12 p-0 my-6 text-sm font-medium"
                  htmlType="submit"
                  type="primary"
                  size="large"
                >
                  {isLoading ? 'Loading...' : 'Submit'}
                </Button>
              </Form.Item>
            </Form>
          </div>
          {/* <div className="p-6 text-center bg-gray-100 dark:bg-white10 rounded-b-md">
        <p className="mb-0 text-sm font-medium text-body dark:text-white60">
          Don`t have an account?
          <Link to="/register" className="ltr:ml-1.5 rtl:mr-1.5 text-[#FF7991] hover:text-primary">
            Sign up
          </Link>
        </p>
      </div> */}
        </div>
      </Col>
    </Row>
  );
};

export default Reset;

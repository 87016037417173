import Cookies from 'js-cookie';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { loginDashboardAdmin } from '../../api/management-users';
import { getRole } from '../../api/roles';
import { message } from 'antd';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr } = actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const res = await loginDashboardAdmin(values.email, values.password);
      console.log(res);
      if (!res.success) {
        message.error(res.error);
        dispatch(loginErr(res.error));
      } else {
        const res2 = await getRole(res?.user?.role);
        console.log(res2);
        if (res2.name == 'super-admin') {
          res.user.isSuperAdmin = true;
        } else {
          res.user.permissions = res2.permissions;
        }
        res.user.roleName = res2.title;
        delete res.user.password;
        // const response = await DataService.post('/login', values);

        // Cookies.set('access_token', res.user.email);
        Cookies.set('logedIn', true);
        dispatch(loginSuccess(res.user));
        callback();
      }
    } catch (err) {
      console.log(err);
      dispatch(loginErr(err));
    }
  };
};

const register = (values) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/register', values);
      if (response.data.errors) {
        dispatch(loginErr('Registration failed!'));
      } else {
        dispatch(loginSuccess(response));
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      Cookies.remove('logedIn');
      Cookies.remove('access_token');
      dispatch(logoutSuccess());
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut, register };

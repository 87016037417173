/*·eslint-disable·*/
import React, { useEffect, useState, lazy, Suspense } from 'react';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { ConfigProvider, Spin } from 'antd';
import { store, persistor } from './redux/store';
import Reset from './container/resetPassword/reset';
import Admin from './routes/admin';
import Auth from './routes/auth';
import UpdateCard from './container/updateCard';
import './static/css/style.css';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';
import 'antd/dist/antd.less';
import { PersistGate } from 'redux-persist/integration/react';
import OnboardSuccess from './container/onboardSuccess';

const NotFound = lazy(() => import('./container/pages/404'));
const { theme } = config;
function ProviderConfig() {
  const { rtl, isLoggedIn, mainContent } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      mainContent: state.ChangeLayoutMode.mode,
      isLoggedIn: state.auth.login,
    };
  });
  const [path, setPath] = useState(window.location.pathname);
  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);
  return (
    <Suspense
      fallback={
        <div className="w-full h-full flex items-center justify-center">
          <div className="spin">
            <Spin />
          </div>
        </div>
      }
    >
      <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
        <ThemeProvider theme={{ ...theme, rtl, mainContent }}>
          <Router basename={process.env.PUBLIC_URL}>
            <Routes>
              {!isLoggedIn ? (
                <Route path="/*" element={<Auth />} />
              ) : (
                <>
                  <Route path="/admin/*" element={<ProtectedRoute path="/*" Component={Admin} />} />
                  <Route path="*" element={<NotFound />} />
                </>
              )}
              {isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (
                <Route path="/" element={<Navigate to="/admin" />} />
              )}
              <Route path="/reset-password/:token" element={<Reset />} />
              <Route path="/api/update-card" element={<UpdateCard />} />
              <Route path="/api/account-success" element={<OnboardSuccess />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </ConfigProvider>
    </Suspense>
  );
}
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ProviderConfig />
      </PersistGate>
    </Provider>
  );
}
export default App;

import { Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
const ApiUrl = process.env.REACT_APP_API_ENDPOINT;
// const stripePromise = loadStripe(
//   'pk_test_51KpK8bCfPMYKQLpFghVq7ZHBXngJgYlL3RFdzXpPtMdSYCwGOPsm6ALbzwOOjL20YTeZIlm0O2WpI6Wk0VKAKZgY00ZCpVDehF',
// ); // Replace with your actual publishable key

//sk_test_51KpK8bCfPMYKQLpFrmP4uXuYDtoiB2yfa1YDAnRBEjO58kC4IjFTQjZyDBeP6a0I1NRq0DA1FNlHJX9UgyVJTMei00UBbRKV0A

// const CheckoutForm = () => {
//   const [stripe, setStripe] = useState(null);
//   const [cardElement, setCardElement] = useState(null);

//   useEffect(() => {
//     const initializeStripe = async () => {
//       const stripeInstance = await stripePromise;
//       setStripe(stripeInstance);

//       const elements = stripeInstance.elements();
//       const card = elements.create('card');
//       card.mount('#card-element');
//       setCardElement(card);
//     };

//     initializeStripe();
//   }, []);

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     const { token, error } = await stripe.createToken(cardElement);

//     if (error) {
//       console.error(error.message);
//     } else {
//       // Send the token to your server for further processing
//       console.log(token);
//     }
//   };

//   return (
//     <div className="w-10/12 mx-auto bg-white p-4">
//       <form onSubmit={handleSubmit}>
//         <div id="card-element"></div>
//         <button type="submit" className="mt-10">
//           Submit Payment
//         </button>
//       </form>
//     </div>
//   );
// };

export default function Stripe() {
  const [stripeAcc, setStripeAcc] = useState();
  const [requirementNeeded, setRequirementNeeded] = useState(false);
  const [onboardLink, setonboardLink] = useState('');
  const params = useParams();
  console.log(params);
  const callApi = async () => {
    try {
      const res = await axios.post(
        `${ApiUrl}/api/get-customer`,
        { accId: params.id },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      );
      console.log(res);
      setStripeAcc(res?.data?.customer);
    } catch (error) {
      console.log(error);
    }
  };
  const generateLink = async () => {
    try {
      const res = await axios.post(`${ApiUrl}/api/onboard-user`, {
        uid: params.id,
      });
      setonboardLink(res.data.url);
      console.log(res.data.url);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    callApi();
    generateLink();
  }, []);
  console.log(stripeAcc);
  return (
    <div>
      {/* <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements> */}
      <div>
        {stripeAcc?.requirements &&
          stripeAcc?.requirements?.currently_due?.map((item) => {
            return <span className="capitalize">{item.replace(/[-._]/g, ' ')}</span>;
          })}
      </div>
      <div className="bg-white w-3/4 rounded my-5 mx-auto p-5">
        <h1>Stripe Account Status:</h1>
        <table className="w-full border-separate border-spacing-y-5">
          {Object.entries(stripeAcc?.requirements ?? []).map(([key, value]) =>
            key == 'pending_verification' || key == 'disabled_reason' ? (
              <tr>
                <td className="font-semibold capitalize mr-3">{key?.replace(/[-._]/g, ' ')}: </td>
                <td className="text-capitalize">
                  {value?.length > 0 ? (
                    value?.map((item) => item.replace(/[-._]/g, ' ' + ', '))
                  ) : (
                    <span className="text-success">None</span>
                  )}
                  {value?.length > 0 && setRequirementNeeded(true)}
                </td>
                <br />
              </tr>
            ) : (
              value?.length > 0 && setRequirementNeeded(true)
            ),
          )}
          <tr>
            <td></td>
            <td>
              {requirementNeeded && (
                <Link className="rounded bg-primary p-1 text-white" to={onboardLink}>
                  Complete Verification
                </Link>
              )}
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}

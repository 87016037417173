import { addDoc, collection, getDocs, getDoc, doc, deleteDoc, updateDoc } from '@firebase/firestore';
import { firestore } from '../config/api/firebase';

export const getAllRoles = async () => {
  const rolesList = [];
  const ref = collection(firestore, 'roles'); // Firebase creates this automatically
  const data = await getDocs(ref);
  data?.forEach((doc) => {
    rolesList.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return rolesList;
};

export const createRole = async (data) => {
  try {
    const ref = collection(firestore, 'roles');
    const docRef = await addDoc(ref, data);

    console.log('Role created with ID: ', docRef.id);

    return docRef.id; // Return the ID of the created user
  } catch (error) {
    console.error('Error creating Role: ', error.message);
  }
};
export const getRole = async (id) => {
  try {
    if (!id) {
      throw new Error('Role ID is undefined or null.');
    }

    const roleRef = doc(firestore, 'roles', id);
    const roleDoc = await getDoc(roleRef);

    if (roleDoc.exists()) {
      return {
        id: roleDoc.id,
        ...roleDoc.data(),
      };
    } else {
      console.error('Role document does not exist for the provided ID:', id);
      return null;
    }
  } catch (error) {
    console.error('Error getting role by id:', error.message);
    return null;
  }
};

export const updateRole = async (id, data) => {
  const roleRef = doc(firestore, 'roles', id);
  try {
    const res = await updateDoc(roleRef, data);
    console.log(`careTaker with ID ${id} successfully updated.`);
    return true; // Return true to indicate successful update
  } catch (error) {
    console.error('Error updating careTaker:', error.message);
    return false; // Return false to indicate failure
  }
};
export const deleteRole = async (id) => {
  const roleRef = doc(firestore, 'roles', id);

  try {
    await deleteDoc(roleRef);
    console.log(`Role with ID ${id} successfully deleted.`);
    return true; // Return true to indicate successful deletion
  } catch (error) {
    console.error('Error deleting Role:', error.message);
    return false; // Return false to indicate failure
  }
};

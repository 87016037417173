import React, { lazy, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { usePermissions } from '../../utility/checkPermissions';
import { useSelector } from 'react-redux';

const Dashboard = lazy(() => import('../../container/dashboard'));
const NotFound = lazy(() => import('../../container/pages/404'));

function DashboardRoutes() {
  const [permissions, setPermissions] = useState([]);
  const { authUser } = useSelector((state) => {
    return {
      authUser: state.auth.user,
    };
  });

  const permissionsFromHook = usePermissions('dashboard');

  useEffect(() => {
    setPermissions(permissionsFromHook);
  }, [permissionsFromHook]);
  console.log(permissions);
  return (
    <Routes>
      {(authUser?.isSuperAdmin || permissions?.dashboard?.includes('read')) && <Route index element={<Dashboard />} />}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default DashboardRoutes;
